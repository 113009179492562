<template>
  <web-layout :title="$t('releaseHistory.releaseHistory')">
    <template>
      <div class="container">
        <div class="version_header">
          <div class="version_statistics_box">
            <div class="statistics_title">{{ $t('releaseHistory.updateStatisics') }}</div>
            <div class="statistics_content_box">
              <div class="statistics_item">
                <div class="item_title">{{ $t('releaseHistory.lastUpdateDate') }}</div>
                <div class="item_content">{{ newversiondate }}</div>
              </div>
              <div class="statistics_item">
                <div class="item_title">{{ $t('releaseHistory.updateNum') }}</div>
                <div class="item_content">{{ updatenumber }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="version_main">
          <div class="version_main_title">
            <div class="record_title">{{ $t('releaseHistory.updateRecords') }}</div>
            <div class="screen_item">
              <span>{{ $t('releaseHistory.showInfo') }}</span>
              <el-checkbox-group v-model="screenVal" @change="changeScreen">
                <el-checkbox label="all">{{ $t('common.all') }}</el-checkbox>
                <el-checkbox label="1">{{ $t('releaseHistory.lastMonth') }}</el-checkbox>
                <el-checkbox label="3">{{ $t('releaseHistory.lastThreeMonth') }}</el-checkbox>
                <el-checkbox label="6">{{ $t('releaseHistory.halfaYear') }}</el-checkbox>
                <el-checkbox label="12">{{ $t('releaseHistory.lastYear') }}</el-checkbox>
              </el-checkbox-group>
              <!-- <span>{{screenDataJson[languageVerson]["SHOWINFO"]}}</span> -->
              <!-- <label><input type="checkbox" value="all" v-model="screenVal" @change="changeScreen($event)"  />{{screenDataJson[languageVerson]["ALL"]}}</label>
                  <label><input type="checkbox" value="1" v-model="screenVal" @change="changeScreen($event)"/>{{screenDataJson[languageVerson]["LASTMONTH"]}}</label>
                  <label><input type="checkbox" value="3" v-model="screenVal" @change="changeScreen($event)"/>{{screenDataJson[languageVerson]["LASTTHREEMONTH"]}}</label>
                  <label><input type="checkbox" value="6" v-model="screenVal" @change="changeScreen($event)"/>{{screenDataJson[languageVerson]["HALFAYEAR"]}}</label>
                  <label><input type="checkbox" value="12" v-model="screenVal" @change="changeScreen($event)"/>{{screenDataJson[languageVerson]["LASTYEAR"]}}</label> -->
            </div>
          </div>
          <div class="record_list">
            <template v-if="historyList.length > 0">
              <div class="record_item_box" v-for="(item, key) of historyList" :key="key">
                <div class="record_item_left">
                  <div class="time_title">{{ $t('releaseHistory.updateDate') }}</div>
                  <div class="time_content">{{ item.releasedate }}</div>
                  <div class="record_tip_box">{{ $t('releaseHistory.updateLog') }}</div>
                </div>
                <div class="record_item_main">
                  <div class="record_logo"><img src="../../assets/logo_mini.png" /></div>
                  <div class="record_content">
                    <p v-for="(content, contentkey) of item.releaseLogList" :key="contentkey">{{ content }}</p>
                  </div>
                </div>
              </div>
            </template>

            <div v-if="historyList.length == 0">
              <div class="empty-box">
                <!-- {{pageText.NOPICCATDATA}} -->
              </div>
            </div>
          </div>

          <pagination-box :total="pagesObj.total" :currentPage="pagesObj.page" :pageSize="pagesObj.pagesize" @current-change="getHistoryList"></pagination-box>
        </div>
      </div>
    </template>
  </web-layout>
</template>

<script>
import index from './index'
export default index
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import './index.scss';
</style>
