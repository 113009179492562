// import axios from "axios";
export default {
  name: 'RegisterPage',
  data() {
    return {
      // 联系方式下拉弹窗是否处于显示状态,
      isContactPopperActive: false,
      langcode: this.$store.getters.lang,
      // 当前tab激活的值
      loginMethod: 'phone',
      // 语言列表
      languageList: [{
        value: 'en',
        label: 'English'
      }, {
        value: 'zh',
        label: '简体中文'
      }, {
        value: 'zht',
        label: '繁體中文(港澳)'
      }, {
        value: 'tw',
        label: '繁體中文(台灣)'
      },],
      // 注册表单
      registerForm: {
        areacode: '',
        phone: '', // 手机登录 手机号
        password: '', // 手机登录 密码
        repwd: '', // 账号登录 密码
        code: ''
      },
      countryList: [
        {
          id: '86',
          name: 'CN +86',
        },
        {
          id: '852',
          name: 'HK +852',
        },
        {
          id: '853',
          name: 'MO +853',
        },
        {
          id: '886',
          name: 'TW +886',
        },
        {
          id: '60',
          name: 'MAS +60',
        },
        {
          id: '65',
          name: 'SG +65',
        },
        {
          id: '61',
          name: 'AU +61',
        },
      ],
      countryVal: '',
      errmsg: '',
      // 自定义区号
      inputAreacodeList: [],
      inputAreacodeShow: false,
      imgcode: '',
      codeDialog: false,
      imgsrc: '',
      codeInputTip: '',
      wait: 60,
      // 短信发送
      isSend: true,
      isFrom313: false, // 是否从313跳转而来
      langcode313: '', //313fm跳来前的语言
      isShowRegisteredDialog: false, // 是否显示已注册的弹窗

    }
  },
  created() {
    // 跳转到官网首页并弹出表单
    if (location.host.indexOf('cn.313fm.com') > -1) {
      location.href = 'https://www.313fm.com/sc/index.html?showcontactform'
    }

    if (this.$route.query.langcode) {
      this.switchLanguage(this.$route.query.langcode.toLowerCase())
    }
    this.setAreacode()
    this.getFromStr()
  },
  mounted() {
    if (location.origin.indexOf('inter.313fm.com') > -1) {
      this.setGoogleGtag()
    }
  },
  computed: {
    // 客服配置
    serviceOpt() {
      const serviceOpt = {
        tc: {
          qrcode: require('@/assets/register/whatsapp_zht.png'),
          app: 'WhatsApp',
          appIcon: 'el-icon-whatsapp',
          contact: '+852 8111 4289'
        },
        tw: {
          qrcode: require('@/assets/register/line_tw.png'),
          app: 'Line',
          appIcon: 'el-icon-line',
          contact: '+886 04 2316 8028'
        },
        sg: {
          qrcode: require('@/assets/register/whatsapp_sg.png'),
          app: 'WhatsApp',
          appIcon: 'el-icon-whatsapp',
          contact: '+65 9828 3728'
        },
      }
      return serviceOpt[this.$route.query.from313] || ''
    },
    // 注册校验规则
    registerFormRules() {
      // 手机号校验
      const phoneValidator = (rule, value, cb) => {
        if (rule.required) {
          if (value) {
            // 是否选择区号
            console.log('this.registerForm.areacode', this.registerForm.areacode);
            if (this.registerForm.areacode) {
              if (this.registerForm.areacode.trim() && this.registerForm.areacode != 'more') {
                cb()
              } else {
                cb(new Error(this.$t('login.loginAreaCodeTips')))
              }
            } else {
              cb(new Error(this.$t('login.loginAreaCodeTips')))
            }
          } else {
            cb(new Error(this.$t('login.loginPhoneTips')))
          }
        } else {
          cb()
        }
      }
      // 密码校验
      const rePasswordValidator = (rule, value, cb) => {
        if (value) {
          if (this.registerForm.password == value) {
            cb()
          } else {
            cb(new Error(this.$t('register.rePassTips')))
          }
        } else {
          cb(new Error(this.$t('register.rePassTips')))
        }

      }
      return {
        phone: [{ required: true, validator: phoneValidator, trigger: 'blur' }],
        password: [{ required: true, message: this.$t('register.configNewPassFormat'), trigger: 'blur', min: 6, max: 20 }],
        repwd: [{ required: true, validator: rePasswordValidator, trigger: 'blur', min: 6, max: 20 }],
        code: [{ required: true, message: this.$t('register.inputCodeError'), trigger: 'blur' }],
      }
    },
    // 联系方式列表
    contactList() {
      return [
        {
          id: 'sg',
          label: this.$t('login.labelSg') + ' +65 9828 3728',
          value: '6598283728'
        },
        {
          id: 'au',
          label: this.$t('login.labelAu') + ' +61 412 102 818',
          value: '61412102818'
        },
        {
          id: 'hk',
          label: this.$t('login.labelHk') + ' +852 8111 4289',
          value: '85281114289'
        },
        {
          id: 'tw',
          label: this.$t('login.labelTw') + ' +886 04 2316 8028',
          value: '8860423168028'
        },
        {
          id: 'cn',
          label: this.$t('login.labelCn') + ' +86 183 2088 6254',
          value: '8618320886254'
        },
        {
          id: 'email',
          label: 'sales@313fm.com',
          value: 'sales@313fm.com'
        },
      ]
    },
    // 当前语言
    currentLang() {
      let lang = this.langcode || 'zh'
      let matchItem = this.languageList.find(lItem => lItem.value.toLowerCase() === lang.toLowerCase())
      return matchItem
    },
    // 当前语言code，用来动态banner图片的
    currentLangCode() {
      if (this.langcode === 'tw') return 'zht'
      return this.langcode.toLowerCase()
    }
  },
  methods: {
    // 设置google点击转换
    setGoogleGtag() {
      let gtagScript = document.createElement('script');
      gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=AW-714536063'
      gtagScript.async = true;
      document.head.appendChild(gtagScript);

      window.dataLayer = window.dataLayer || [];
      window.gtag = function () { window.dataLayer.push(arguments); }
      window.gtag('js', new Date());
      window.gtag('config', 'AW-714536063');
    },
    // 获取来源网站是否为313
    getFromStr() {
      let lcExp = /^(sc|tc|tw|en|sg|my|au|za)$/i
      let from313 = this.$route.query.from313
      let skipreg = this.$route.query.skipreg
      if (from313) {
        this.isFrom313 = true
        this.langcode313 = lcExp.test(from313) ? from313.toLowerCase() : 'tc';
        // 跳过注册直接跳到官网的使用流程页
        if (skipreg && skipreg == '1') {
          location.href = `https://www.313fm.com/${this.langcode313}/user-flow.html`
        }
      }
    },
    checkPhone() {
      if (this.registerForm.phone == '') return

      //* 查询当前服务器手机号
      this.$http.post('/common/checkphone', {
        "phone": this.registerForm.phone,
        "type": "1",
        "langcode": this.langcode,
        "areacode": this.registerForm.areacode
      }, {
        timeout: 60000,
        disabledAuth: true,
        showLoading: false
      }).then((response) => {
        // The phone number has already existed
        // 手机号已存在
        // 手機號已存在
        console.log('response', response);

        if (response.data.success) {
          this.errmsg = '';
          this.isSend = false;
        } else {
          //* 判断是否当前版本服务器是否可注册该区号的手机,不可注册时跳转对应版本服务器
          if (response.data.error) {
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true,
              callback: () => {
                window.location.href = this.$utils.getAnotherSite() + '/#/register'
              }
            })
          }
          if (response.data.code == '402001') {
            this.isShowRegisteredDialog = true
            this.errmsg = this.$t('register.tips_402001');
          } else {
            this.errmsg = response.data.message;
          }
          this.isSend = true;

          // this.errorShow = true;
        }
      })
    },
    // 只输入数字
    handleNumInput(value) {
      /* eslint-disable */
      const reg = /^[\d\.]+$/;
      if (value && !reg.test(value)) {
        this.registerForm.phone = value.replace(/[^\d\.]/g, '');
      }
    },
    //todo 设置默认区号
    setAreacode() {
      //* 默认选择+86中国大陆区号
      let areacodeId = ''
      switch (this.$store.getters.lang) {
        case 'zh':
          areacodeId = '86'
          break;
        case 'zht':
        case 'en':
          areacodeId = '852'
          break;
        case 'tw':
          areacodeId = '886'
          break;
        default:
          areacodeId = ''
          break;
      }
      if (areacodeId) {
        this.registerForm.areacode = areacodeId
      } else {
        this.registerForm.areacode = this.countryList[0].id;
        this.$nextTick(() => {
          this.$refs.registerFormRef.validateField('phone')
        })
        //* 根据域名设置区号
        // if (window.location.origin == getInterSite()) this.areacode = this.countryList[1].id;
      }
    },
    switchLanguage(lang) {
      this.langcode = lang
      this.$store.commit('SET_LANG', lang)
      this.$i18n.locale = this.$store.getters.lang
      this.$nextTick(() => {
        this.$refs.registerFormRef.clearValidate()
      })
      this.errmsg = ''
    },

    // 选择区号
    handelChangeAreacode(val) {
      //* 根据选择切换服务器版本
      if (window.location.origin == this.$utils.getInternalSite()) {
        if (val != this.countryList[0].id && val != 'more') {
          //* 在国内版选择+86外的号码就跳转到国际版注册页面
          window.location.href = this.$utils.getInterSite() + '/#/register' + this.setDefaultCountrySearch(val) + (this.isFrom313 ? `&from313=${this.langcode313}` : '')
        }
      } else if (window.location.origin == this.$utils.getInterSite()) {
        if (val == this.countryList[0].id && val != 'more') {
          //* 在国际版选择+86号码就跳转到国内版注册页面
          window.location.href = getInternalSite() + '/#/register' + this.setDefaultCountrySearch(val) + (this.isFrom313 ? `&from313=${this.langcode313}` : '')
        }
      }

      if (val != 'more') {
        //* 重新选择区号时再检测一次是否可以注册该手机号
        this.checkPhone();
      }

      if (val === 'more') {
        this.inputAreacodeShow = true;
        this.$nextTick(() => {
          this.$refs.countryInput.focus();
        });
      }
    },
    //* 获取默认选择区号的url查询参数
    setDefaultCountrySearch(id) {
      if (id == 'more') return '';
      let targetCountry = this.countryList.find(item => {
        return item.id == id
      })
      if (targetCountry) {
        return '?countryId=' + targetCountry.id;
      } else {
        //* 用户自定义输入的区号
        let moreCountry = this.inputAreacodeList.find(item => {
          return item.id == id
        })
        if (moreCountry) return '?countryId=' + moreCountry.id + '&countryName=' + moreCountry.name;
      }
    },
    // 输入自定义区号
    handlecountryInput(val) {
      if (val === '') return;
      this.inputAreacodeList.push({
        id: val,
        name: val,
      });
      this.registerForm.areacode = val;
      // this.checkPhone();
      this.inputAreacodeShow = false;
      this.countryVal = ''
      this.registerForm.inputAreacode = '';
    },
    /**
     * string
     * @param {string} key 对应哪string个的 比如：home
     * @param {string,num} id 对应的id 比如：1
     */
    getBannerImg(key, id) {

      return require(`@/assets/login_banner/${key}_${this.currentLangCode}_${id}.png`)
    },
    // 联系方式下拉弹窗显隐事件
    contactVisibleChange(e) {
      this.isContactPopperActive = e
    },
    getImageCode() {
      this.$http.post('/common/genimgcode', {}, { disabledAuth: true }).then((response) => {
        if (response.data.success) {
          this.imgsrc = response.data.content.imagesrc;
          this.imgcodekey = response.data.content.key;
        } else {
          this.$alert(response.data.message, this.$t('common.alertPrompt'), {
            type: 'error',
            center: true
          })
        }
      })
    },
    // 表单返回
    backForm() {
      let query = this.$route.query
      if (query.from313) {
        this.$router.replace({
          path: '/login',
          query
        })
      } else {
        this.$router.replace('/login')
      }
    },
    trim(str) {
      return str.replace(/(^\s*)|(\s*$)/g, "");
    },
    register() {
      this.$refs.registerFormRef.validate(valid => {
        if (valid) {
          if (typeof window.gtag === 'function') {
            window.gtag('event', 'conversion', { 'send_to': 'AW-714536063/YlpYCPTE0MMZEP_o29QC' });
          }
          this.$http.post('/common/register', {
            "areacode": this.registerForm.areacode,
            "phone": this.registerForm.phone,
            "password": this.registerForm.password,
            "company": '',
            "contact": '',
            "code": this.trim(this.registerForm.code),
            "langcode": this.langcode,
            source: this.isFrom313 ? 'www.313fm.com' : ''
          }, {
            disabledAuth: true,
            timeout: 60000,
            headers: {
              'X-Timezone-Offset': -(new Date().getTimezoneOffset()) + ''
            }
          }).then((response) => {
            let datas = response.data;

            if (datas.success) {
              //* 点击确定后跳转到对应语言登陆页面
              if (this.isFrom313) {
                location.href = `https://www.313fm.com/${this.langcode313}/user-flow.html`
              } else {
                this.$alert(this.$t('register.regSuccessMsg'), this.$t('common.alertPrompt'), {
                  type: 'success',
                  center: true
                }).then(res => {
                  // this.isShowRegisteredDialog = true
                  this.goToLogin()
                })
              }
            } else {
              this.errmsg = datas.message

              // self.loginInter();
            }
          })
        }
      })


    },
    getCode() {
      this.getImageCode()
      this.codeDialog = true
      this.imgcode = ''
      this.codeInputTip = ""
    },
    sendCode() {
      if (this.wait === 60) {
        this.$http.post('/common/gencode', {
          "areacode": this.registerForm.areacode,
          "phone": this.registerForm.phone,
          "type": '1',
          "langcode": this.langcode,
          key: this.imgcodekey,
          code: this.imgcode,
        }, {
          disabledAuth: true,
          timeout: 60000
        }).then((response) => {
          if (response.data.success) {
            let timer = setInterval(() => {
              this.wait = this.wait - 1;
              if (this.wait === 0) {
                clearInterval(timer);
                this.wait = 60;
              }
              this.codeDialog = false
            }, 1000);
          } else {
            if (this.$t(`common.${response.data.message}`).indexOf('common.') == -1) {
              if (response.data.message === 'ERR_NOTICE_0001') {
                this.codeInputTip = '+' + this.registerForm.areacode + this.registerForm.phone + this.$t(`common.${response.data.message}`)
              } else {
                this.codeInputTip = this.$t(`common.${response.data.message}`)
              }
            } else {
              this.codeInputTip = response.data.message
            }
          }
        })
      }
    },

    // 前往登录
    goToLogin() {
      this.$router.push('/login')
    },
    // 前往忘记密码
    goToRetrievePwd() {
      this.$router.push('/forgot')
    },
    // 前往使用流程页
    goToUseFlow() {
      let langcode = 'sc'
      switch (this.langcode) {
        case 'zht':
          langcode = 'tc'
          break;
        case 'tw':
          langcode = 'tw'
          break;
        case 'en':
          langcode = 'en'
          break;
        default:
          langcode = 'sc'
          break;
      }
      window.open('https://www.313fm.com/' + langcode + '/user-flow.html', '_blank')
    },

  }
}