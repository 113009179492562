
export default {
  name: 'ReleaseHistoryPage',
  data() {
    return {
      // 历史记录数据
      historyList: [],
      // 翻页配置
      pagesObj: {
        page: 1,
        pagesize: 10,
        total: 0
      },
      newversiondate: 0,
      updatenumber: 0,
      screenVal: ["all"],

    }
  },
  mounted() {
    this.getHistoryList()


  },
  methods: {
    // 获取历史记录列表
    getHistoryList(page) {
      if (page) {
        this.pagesObj.page = page
      } else {
        this.pagesObj.page = 1
      }
      let postParam = {
        hrid: this.$store.getters.loginid,
        page: this.pagesObj.page,
        pagesize: this.pagesObj.pagesize,
        type: "WEB",
      }
      if (this.screenVal[0] != 'all') {
        postParam['date'] = this.screenVal[0]
      }
      this.$http
        .post('/common/releaseloglist', postParam)
        .then((response) => {
          if (response.data.success) {
            this.historyList = response.data.content.data;
            this.newversiondate = response.data.content.newversiondate
            this.updatenumber = response.data.content.updatenumber
            this.pagesObj = {
              page: response.data.page,
              pagesize: response.data.pagesize,
              total: response.data.total
            }
          } else {
            this.$alert(response.data.message, this.$t('common.alertPrompt'), {
              type: 'error',
              center: true
            })
          }
        })
    },
    changeScreen(event) {
      // let selected = event.target.value
      this.screenVal = [event[event.length - 1]]
      // // vm.$data.currentPage = 1
      this.getHistoryList()
    }

  }
}